export function changeDomain(url) {
  let currentDomain = window.location.hostname

  if (currentDomain === 'localhost') {
    return url
  }
  currentDomain = currentDomain.split('.').slice(-2).join('.')

  const urlObject = new URL(url)
  const originalDomain = urlObject.hostname.split('.').slice(-2).join('.')
  const newHostname = urlObject.hostname.replace(originalDomain, currentDomain)
  urlObject.hostname = newHostname
  return urlObject.toString()
}
