import React, { useEffect, useState } from 'react'
import './App.scss'
import config from 'configs/env'
import { SSO } from '@30shine-fe/sso-erp'
import axios from 'axios'
import DashboardLayout from 'layouts/DashboardLayout'
import { getListMenuAccess } from 'api/users'
import { setUrlAccess } from 'redux/slices/userSlice'
import { useDispatch } from 'react-redux'
import { getAllSalon } from 'pages/checkin/api'
import { fetchAllSalon } from 'pages/checkin/reducers/checkin'

const sso = new SSO(process.env.REACT_APP_ENV || 'test')
function App() {
  const dispatch = useDispatch()

  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const fetchCredentials = async () => {
    try {
      const credentials = await sso.ssoLogin()
      if (credentials) {
        localStorage.setItem('AccessToken', credentials.AccessToken)
        localStorage.setItem('RefreshToken', credentials.RefreshToken)
        localStorage.setItem('IdToken', credentials.IdToken)
        const request = axios.create({
          baseURL: config.base_authenticator_v2_url
        })
        request.defaults.headers.common['Authorization'] = 'Bearer ' + credentials.AccessToken
        await fetchAuthorization(credentials.AccessToken)
        setIsAuthenticated(true)
      }
    } catch (e) {
      console.log(e)
      if (e?.message === 'Request failed with status code 403') {
        setIsAuthenticated(true)
      }
    }
  }
  const fetchAuthorization = async (accessToken) => {
    if (accessToken) {
      try {
        const [menu, salon] = await Promise.all([getListMenuAccess(), getAllSalon()])
        if (menu.data.errorCode === 0 && salon.data.statusCode === 0) {
          dispatch(setUrlAccess(menu.data.data))
          dispatch(fetchAllSalon(salon.data.data))
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    fetchCredentials()
  }, [])

  return <div className='App'>{isAuthenticated ? <DashboardLayout /> : null}</div>
}

export default App
